<template>
    <a-drawer width="50%" title="媒介详情" placement="right" :maskClosable="false" :visible="MediaResourcesDrawerVisible" @close="onClose">
        <a-descriptions title="媒介信息">
            <a-descriptions-item label="媒介照片" :span="3" v-if="screenType == 2">
                <template v-for="(item, index) in mediaInfo.imagesUrl">
                    <img @click="preview(item)" class="img" :key="index" :src="resourcesHost + item" />
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="媒介照片" :span="3" v-if="screenType == 1">
                <template v-for="(item, index) in mediaInfo.realPictureUrl">
                    <img @click="preview(item)" class="img" :key="index" :src="resourcesHost + item" />
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="媒介编号" :span="screenType == 2 ? 2 : 1">
                {{ mediaInfo.id }}
            </a-descriptions-item>
            <a-descriptions-item label="媒介名称">
                {{ mediaInfo.name }}
            </a-descriptions-item>
            <a-descriptions-item label="媒介类型">
                <template v-if="screenType == 2">
                    {{ mediaInfo.type }}
                </template>
                <template v-if="screenType == 1">
                    LED户外大屏
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="刊例价格(元)">
                <template v-if="screenType == 1">
                    {{ mediaInfo.serviceCharge }}
                </template>
                <template v-if="screenType == 2">
                    {{ mediaInfo.price }}
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="尺寸">
                <template v-if="screenType == 1">
                    {{ mediaInfo.resolutionX + "x" + mediaInfo.resolutionY }}
                </template>
                <template v-if="screenType == 2">
                    {{ mediaInfo.size }}
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="投放类型" v-if="screenType == 2">
                <template>
                    {{ mediaInfo.dateType == 0 ? "每日" : mediaInfo.dateType == 1 ? "每周" : mediaInfo.dateType == 2 ? "每月" : mediaInfo.dateType == 3 ? "每年" : "--" }}
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="场景" v-if="screenType == 1">
                <template>
                    {{ mediaInfo.scene }}
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="电话" :span="screenType == 1 ? 3 : 2">
                <template v-if="screenType == 2">
                    {{ mediaInfo.phone }}
                </template>
                <template v-if="screenType == 1">
                    {{ mediaInfo.screenPhone }}
                </template>
            </a-descriptions-item>
            <a-descriptions-item label="地址" :span="3">
                <template> <a-icon style="color: #1890ff" type="environment" @click="mapShow" /> {{ mediaInfo.province + mediaInfo.city + mediaInfo.district + mediaInfo.address }} </template>
            </a-descriptions-item>
        </a-descriptions>
        <Vviewer ref="viewer"></Vviewer>
        <MapModal :showMap="showMap" :closeAmap="closeAmap" :latitude="latitude" :longitude="longitude" :addressInfo="addressInfo" />
    </a-drawer>
</template>
<script>
import Vviewer from "../../../components/Vviewer.vue";
import MapModal from "./MapModal.vue";
export default {
    components: { Vviewer, MapModal },
    props: ["MediaResourcesDrawerVisible", "closeMediaResourcesDrawer", "mediaResourcesId", "screenType", "screenInfo"],
    data() {
        return {
            longitude: "",
            latitude: "",
            addressInfo: "",
            showMap: false,
            mediaInfo: {},
            resourcesHost: process.env.VUE_APP_RESOURCES_HOST,
        };
    },
    watch: {
        MediaResourcesDrawerVisible(data) {
            if (data) {
                if (this.screenType == 2) {
                    this.getMediaDetail();
                } else {
                    this.mediaInfo = this.screenInfo;
                }
            }
        },
    },
    methods: {
        closeAmap() {
            this.longitude = "";
            this.latitude = "";
            this.addressInfo = "";
            this.showMap = false;
        },
        mapShow() {
            this.addressInfo = this.mediaInfo.province + this.mediaInfo.city + this.mediaInfo.district + this.mediaInfo.address;
            this.longitude = this.mediaInfo.longitude;
            this.latitude = this.mediaInfo.latitude;
            this.showMap = true;
        },
        preview(url) {
            this.$refs.viewer.show([
                {
                    thumbnail: process.env.VUE_APP_RESOURCES_HOST + url,
                    source: process.env.VUE_APP_RESOURCES_HOST + url,
                },
            ]);
        },
        getMediaDetail() {
            const _this = this;
            this.$http
                .get("/publishmedia/query?id=" + this.mediaResourcesId)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.mediaInfo = res.data;
                    } else {
                        _this.$Notification["error"]({
                            message: "请求失败！",
                            description: res.msg,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    _this.$Notification["error"]({
                        message: "请求失败！",
                        description: res.msg,
                    });
                });
        },
        onClose() {
            this.closeMediaResourcesDrawer();
        },
    },
};
</script>
<style lang="less" scoped>
.img {
    width: 100px;
    height: 50px;
    margin-right: 8px;
    cursor: pointer;
}
.ant-divider {
    margin: 0 8px;
}
</style>
