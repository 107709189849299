<template>
    <a-modal :visible="verifiedAddModalShow" title="实名认证" @cancel="closeModal" :footer="null">
        <a-result v-if="examineState == 0" status="warning" title="未实名认证">
            <div class="desc">
                <p style="text-align: center">您还未进行实名认证，无法查看联系电话。<a @click="toPersonCenter">立即认证</a></p>
                <p style="text-align: center">如有疑问：请联系客服176-7316-1640</p>
            </div>
        </a-result>
        <a-result v-if="examineState == 2" status="error" title="实名认证未通过!">
            <div class="desc">
                <p style="text-align: center">拒绝原因：{{ userAuthenticationFail }} <a @click="toPersonCenter">重新认证</a></p>
                <p style="text-align: center">如有疑问：请联系客服176-7316-1640</p>
            </div>
        </a-result>
        <a-result v-if="examineState == 3" title="实名认证审核中···">
            <div class="desc">
                <p style="text-align: center">审核通过后方可查看联系电话。</p>
                <p style="text-align: center">审核需要1-3个工作日，请耐心等待···</p>
                <p style="text-align: center">如有疑问：请联系客服176-7316-1640</p>
            </div>
        </a-result>
    </a-modal>
</template>
<script>
export default {
    props: ["verifiedAddModalShow", "closeVerifiedAddModal", "examineState", "userAuthenticationFail"],
    data() {
        return {
            loading: false,
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            form: {
                userName: "",
            },
            userInfo: {},
        };
    },
    watch: {
        verifiedAddModalShow(data) {
            if (data) {
                this.userInfo = this.$store.getters.userInfo;
            }
        },
        op(data) {
            if (data == "updata") {
                this.userAuthenticationQuery();
            }
        },
    },
    methods: {
        toPersonCenter() {
            this.$router.push({ name: "PersonCenter" });
        },
        closeModal() {
            this.closeVerifiedAddModal();
        },
    },
};
</script>
<style lang="less" scoped>
.alert {
    margin-bottom: 16px;
}
.desc {
    p {
        margin-bottom: 16px;
    }
}
</style>
