<template>
	<div class="pageContainer">
		<a-row class="mediaResourcesManagement">
			<a-form-model layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
				<a-form-model-item prop="type">
					<a-select v-model="formInline.type" style="width: 150px" placeholder="媒介类型" allowClear>
						<a-select-option v-for="item in mediumTypeList" :key="item.value" :value="item.value">{{ item.value }}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item prop="name">
					<a-input v-model="formInline.name" placeholder="请输入媒介名称" allowClear></a-input>
				</a-form-model-item>
				<a-form-model-item class="search">
					<a-dropdown-button type="primary" html-type="submit">
						<a-icon type="search" />
						查询
						<a-menu slot="overlay">
							<a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
						</a-menu>
					</a-dropdown-button>
				</a-form-model-item>
			</a-form-model>
			<a-table rowKey="id" :columns="columns" :data-source="data" class="table" :loading="tableLoading" :scroll="{ y: tableHeight }">
				<template slot="id" slot-scope="text">
					<a-tooltip :title="text" placement="topLeft">{{ text }}</a-tooltip>
				</template>
				<template slot="type" slot-scope="text, record">
					<span v-if="record.screenType == 1">LED户外大屏</span>
					<span v-if="record.screenType == 2">{{ record.publishType }}</span>
				</template>
				<template slot="name" slot-scope="text, record">
					<span v-if="record.screenType == 1">{{ text }}</span>
					<span v-if="record.screenType == 2">{{ record.publishName }}</span>
				</template>
				<template slot="size" slot-scope="text, record">
					<span v-if="record.screenType == 1">{{ record.resolutionX + 'x' + record.resolutionY }}</span>
					<span v-if="record.screenType == 2">{{ text }}</span>
				</template>
				<template slot="address" slot-scope="text">
					<a-tooltip :title="text" placement="topLeft">{{ text || '--' }}</a-tooltip>
				</template>
				<template slot="screenPhone" slot-scope="text">
					<span v-if="examineState == 1">{{ text }}</span>
					<a v-else @click="queryPhone">查看电话</a>
				</template>
				<template slot="action" slot-scope="text, record">
					<a @click="queryDetail(record)">查看</a>
				</template>
			</a-table>
		</a-row>
		<VerifiedResultModal
			:userAuthenticationFail="userAuthenticationFail"
			:verifiedAddModalShow="verifiedAddModalShow"
			:closeVerifiedAddModal="closeVerifiedAddModal"
			:examineState="examineState"
		/>
		<MediaResourcesDrawer
			:mediaResourcesId="mediaResourcesId"
			:screenInfo="screenInfo"
			:screenType="screenType"
			:MediaResourcesDrawerVisible="MediaResourcesDrawerVisible"
			:closeMediaResourcesDrawer="closeMediaResourcesDrawer"
		/>
	</div>
</template>
<script>
import VerifiedResultModal from './components/VerifiedResultModal';
import MediaResourcesDrawer from './components/MediaResourcesDrawer';
export default {
	components: { VerifiedResultModal, MediaResourcesDrawer },
	data() {
		return {
			screenType: 1,
			screenInfo: {},
			MediaResourcesDrawerVisible: false,
			mediaResourcesId: '',
			verifiedAddModalShow: false,
			data: [],
			tableHeight: 450,
			tableLoading: true,
			formInline: {
				type: undefined,
				name: undefined,
			},
			columns: [
				{
					title: '媒介编号',
					dataIndex: 'id',
					key: 'id',
					ellipsis: true,
					scopedSlots: { customRender: 'id' },
				},
				{
					title: '媒介名称',
					dataIndex: 'name',
					key: 'name',
					scopedSlots: { customRender: 'name' },
				},
				{
					title: '媒介类型',
					dataIndex: 'type',
					key: 'type',
					scopedSlots: { customRender: 'type' },
				},
				{
					title: '尺寸',
					dataIndex: 'size',
					key: 'size',
					scopedSlots: { customRender: 'size' },
				},
				{
					title: '电话',
					dataIndex: 'screenPhone',
					key: 'screenPhone',
					scopedSlots: { customRender: 'screenPhone' },
				},
				{
					title: '所在区域',
					dataIndex: 'province',
					key: 'province',
					customRender(text, record) {
						return record.province + record.city + record.district;
					},
				},
				{
					title: '详细地址',
					dataIndex: 'address',
					key: 'address',
					ellipsis: true,
					scopedSlots: { customRender: 'address' },
				},
				{
					title: '操作',
					dataIndex: 'action',
					key: 'action',
					scopedSlots: { customRender: 'action' },
				},
			],
			userInfo: {},
			mediumTypeList: [],
			examineState: null,
			userAuthenticationFail: '',
		};
	},
	created() {
		this.userInfo = this.$store.getters.userInfo;
		this.getUserauthentication();
		this.getPageList();
		this.getMediumTypeList();
		if (document.body.clientWidth <= 1366) {
			this.tableHeight = 390;
		} else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
			this.tableHeight = 500;
		} else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
			this.tableHeight = 530;
		} else if (document.body.clientWidth > 1600) {
			this.tableHeight = 650;
		}
	},
	methods: {
		closeMediaResourcesDrawer() {
			this.mediaResourcesId = '';
			this.screenType = 1;
			this.screenInfo = {};
			this.MediaResourcesDrawerVisible = false;
		},
		queryDetail(data) {
			this.screenType = data.screenType;
			this.mediaResourcesId = data.id;
			if (data.screenType == 1) {
				this.screenInfo = data;
			}
			this.MediaResourcesDrawerVisible = true;
		},
		closeVerifiedAddModal() {
			this.verifiedAddModalShow = false;
		},
		queryPhone() {
			this.verifiedAddModalShow = true;
		},
		getUserauthentication() {
			const _this = this;
			this.$http
				.get('/userauthentication/query?userCode=' + this.userInfo.userCode)
				.then(res => {
					if (res.rescode == 200) {
						_this.examineState = res.data.examineState;
						if (res.data.examineState == 2) {
							_this.userAuthenticationFail = res.data.remark;
						}
					} else {
						if (res.msg == '未找到') {
							_this.examineState = 0;
						} else {
							_this.$Notification['error']({
								message: '请求失败！',
								description: res.msg,
							});
						}
					}
				})
				.catch(err => {
					console.log(err);
					_this.$Notification['error']({
						message: '请求失败！',
						description: res.msg,
					});
				});
		},
		reset() {
			this.$refs.ruleForm.resetFields();
			this.tableLoading = true;
			this.getPageList();
		},
		handleSubmit() {
			this.tableLoading = true;
			this.getPageList();
		},
		getMediumTypeList() {
			let params = {};
			const _this = this;
			this.$http
				.post('/publishmediatype/queryList', params)
				.then(res => {
					let dataList = res.data.dataList;
					dataList.unshift(
						{
							value: '全部媒介资源',
						},
						{
							value: 'LED户外大屏',
						},
					);
					_this.mediumTypeList = dataList;
				})
				.catch(err => {
					console.log(err);
					_this.$Notification['error']({
						message: '请求失败！',
						description: res.msg,
					});
				});
		},
		getPageList() {
			let params = {};
			if (this.formInline.type && this.formInline.type != '' && this.formInline.type != '全部媒介资源') {
				params.type = this.formInline.type;
			}
			if (this.formInline.name && this.formInline.name != '') {
				params.name = this.formInline.name;
			}
			const _this = this;
			this.$http
				.post('/publishmedia/queryMediaResources', params)
				.then(res => {
					if (res.rescode == 200) {
						let dataList = res.data.dataList;
						dataList.map(item => {
							if (item.screenType == 1) {
								item.realPictureUrl = item.realPictureUrl.split(',');
							}
						});
						this.data = dataList;
					} else {
						_this.$Notification['error']({
							message: '请求失败！',
							description: res.msg,
						});
					}
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					_this.tableLoading = false;
				});
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	.mediaResourcesManagement {
		padding: 16px;
		height: 100%;
		background: #fff;
		.search {
			float: right;
		}
	}
}
</style>
