<template>
    <a-modal :width="1000" :visible="showMap" title="地图显示" @cancel="closeMap" :footer="null">
        <div id="amap-container">
            <a-alert class="alert" :message="'地址：' + addressInfo"></a-alert>
            <div id="custom-amap" />
        </div>
    </a-modal>
</template>

<script>
const AMap = window.AMap;
export default {
    name: "AMap",
    props: ["showMap", "closeAmap", "latitude", "longitude", "addressInfo"],
    data() {
        return {
            defaultCity: "北京",
            // 地图对象
            map: null,
            // 地址对应的经纬度信息
            position: {},
            // 地图标记
            marker: null,
            // 地址解析（正向）
            geocoder: "",
            // 地址名称
            name: "",
        };
    },
    watch: {
        showMap(data) {
            if (data) {
                // 初始化地图页面
                const _this = this;
                setTimeout(() => {
                    _this.initMap();
                }, 500);
            }
        },
    },
    mounted() {},
    beforeDestroy() {
        // 销毁地图
        if (this.map) {
            this.map.destroy();
        }
    },
    methods: {
        closeMap() {
            // 销毁地图
            if (this.map) {
                this.map.destroy();
            }
            this.closeAmap();
        },
        //   初始化地图页面
        initMap() {
            this.map = new AMap.Map("custom-amap", {
                resizeEnable: true,
                zoom: 50,
            });
            // 添加工具栏
            this.map.plugin(["AMap.ToolBar", "AMap.Scale", "AMap.OverView"], () => {
                // 工具条
                const toolbar = new AMap.ToolBar();
                // 比例尺
                const scale = new AMap.Scale();
                // 显示鹰眼
                const overView = new AMap.OverView();
                this.map.addControl(toolbar);
                this.map.addControl(scale);
                this.map.addControl(overView);
            });
            this.marker = new AMap.Marker();
            this.map.setCenter([this.longitude, this.latitude]);
            const lnglat = [this.longitude, this.latitude];
            this.marker.setPosition(lnglat);
            this.setMaker();
        },
        // 添加maker
        setMaker() {
            // this.marker = new AMap.Marker();
            this.map.add(this.marker);
            // 添加解析地理位置插件
            this.map.plugin("AMap.Geocoder", () => {
                // 异步加载插件
                this.geocoder = new AMap.Geocoder({
                    city: this.defaultCity, // 默认：“全国”
                    radius: 1000, // 范围，默认：500
                });
            });
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .ant-modal-body {
    padding: 0;
}
#amap-container {
    position: relative;
    #custom-amap {
        height: 60vh;
        width: 100%;
        border: 1px solid #ccc;
    }

    .input-with {
        z-index: 1;
        width: 300px;
        position: absolute;
        right: 8px;
        top: 8px;
    }
    .alert {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 100;
    }
}
.amap-sug-result {
    z-index: 99999;
}
</style>
